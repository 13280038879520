export default {
  terminal: [
    { value: 'Anchorage' },
    { value: 'KEPPEL' },
    { value: 'BRANI' },
    { value: 'PASIR PANJANG' },
    { value: 'JURONG PORT' },
    { value: 'Oil Tanking' },
    { value: 'Banyan Horizon' },
    { value: 'Vopak Banyan' },
    { value: 'Helios Terminal' },
    { value: 'Universal Terminal' },
    { value: 'Seraya Power Station' },
    { value: 'Vopak Sebarok' },
    { value: 'Tank Store' },
    { value: 'Vopak Terminal' },
    { value: 'CRUISE CENTRE' },
    { value: 'MARINA BAY CRUISE CENTRE' }
  ],
  berth: {
    Anchorage: [
      { value: 'ACBTH' },
      { value: 'ACGP' },
      { value: 'AMOW' },
      { value: 'AEBA' },
      { value: 'AEBB' },
      { value: 'AEPBC' },
      { value: 'ASCB' },
      { value: 'ASCA' },
      { value: 'AEPBB' },
      { value: 'AESPA' },
      { value: 'AEBC' },
      { value: 'AEHA' },
      { value: 'AEPA' },
      { value: 'AEW' },
      { value: 'AEHB' },
      { value: 'AEHC' },
      { value: 'AWQI' },
      { value: 'AWW' },
      { value: 'AWPA' },
      { value: 'AWH' },
      { value: 'AWPB' },
      { value: 'ARAFR' },
      { value: 'ARP' },
      { value: 'ASPLU' },
      { value: 'ASPP' },
      { value: 'ASPH' },
      { value: 'ASUBB' },
      { value: 'ASUEX' },
      { value: 'ASSPU' },
      { value: 'ASUBA' },
      { value: 'ASH' },
      { value: 'AVLCC' }
    ],
    KEPPEL: [
      { value: 'K-09' },
      { value: 'K-10' },
      { value: 'K-11' },
      { value: 'K-12' },
      { value: 'K-13' },
      { value: 'K-14' },
      { value: 'K-15' },
      { value: 'K-16' },
      { value: 'K-17' },
      { value: 'K-18' },
      { value: 'K-19' },
      { value: 'K-20' },
      { value: 'K-21' },
      { value: 'K-22' },
      { value: 'K-23' }
    ],
    BRANI: [
      { value: 'B-01' },
      { value: 'B-02' },
      { value: 'B-03' },
      { value: 'B-04' },
      { value: 'B-05' },
      { value: 'B-06' },
      { value: 'B-07' },
      { value: 'B-08' },
      { value: 'B-09' }
    ],
    'PASIR PANJANG': [
      { value: 'P01' },
      { value: 'P02' },
      { value: 'P03' },
      { value: 'P04' },
      { value: 'P05' },
      { value: 'P06' },
      { value: 'P07' },
      { value: 'P08' },
      { value: 'P09' },
      { value: 'P10' },
      { value: 'P11' },
      { value: 'P12' },
      { value: 'P13' },
      { value: 'P14' },
      { value: 'P15' },
      { value: 'P16' },
      { value: 'P17' },
      { value: 'P18' },
      { value: 'P19' },
      { value: 'P20' },
      { value: 'P21' },
      { value: 'P22' },
      { value: 'P23' },
      { value: 'P24' },
      { value: 'P25' },
      { value: 'P26' },
      { value: 'P27' },
      { value: 'P28' },
      { value: 'P29' },
      { value: 'P30' },
      { value: 'P31' },
      { value: 'P32' },
      { value: 'P33' },
      { value: 'P34' },
      { value: 'P35' },
      { value: 'P36' },
      { value: 'P37' },
      { value: 'P38' },
      { value: 'P39' },
      { value: 'P40' },
      { value: 'P41' }
    ],
    'JURONG PORT': [
      { value: 'J01' },
      { value: 'J02' },
      { value: 'J02A' },
      { value: 'J03' },
      { value: 'J03A' },
      { value: 'J04' },
      { value: 'J04A' },
      { value: 'J05' },
      { value: 'J06' },
      { value: 'J07' },
      { value: 'J08' },
      { value: 'J09' },
      { value: 'J10' },
      { value: 'J11' },
      { value: 'J12' },
      { value: 'J12A' },
      { value: 'J12B' },
      { value: 'J13' },
      { value: 'J14' },
      { value: 'J15' },
      { value: 'J16' },
      { value: 'J-17' },
      { value: 'J-18' },
      { value: 'J-19' },
      { value: 'J-20' },
      { value: 'J-21' },
      { value: 'J-22' },
      { value: 'J-23' },
      { value: 'J-24' },
      { value: 'J-25' }
    ],
    'Oil Tanking': [
      { value: 'OTK1' },
      { value: 'OTK2' },
      { value: 'OTK3' },
      { value: 'OTK4' },
      { value: 'OTK5' },
      { value: 'OTK6' },
      { value: 'OTK7' },
      { value: 'OTK8' },
      { value: 'OTK9' },
      { value: 'OTK10' },
      { value: 'OTK11' },
      { value: 'OTK12' },
      { value: 'OTK18' },
      { value: 'OTK19' },
      { value: 'OTK20' },
      { value: 'OTK21' },
      { value: 'OTK22' }
    ],
    'Banyan Horizon': [
      { value: 'OBH1' },
      { value: 'OBH2' },
      { value: 'OBH3' },
      { value: 'OBH4' }
    ],
    'Vopak Banyan': [
      { value: 'OBV1' },
      { value: 'OBV2' },
      { value: 'OBV3' },
      { value: 'OBV4' },
      { value: 'OBV5' },
      { value: 'OBV6' },
      { value: 'OBV7' }
    ],
    'Helios Terminal': [
      { value: 'OHT1' },
      { value: 'OHT2' },
      { value: 'OHT3' },
      { value: 'OHT4' },
      { value: 'OHT5' },
      { value: 'OHT6' }
    ],
    'Universal Terminal': [
      { value: 'OMU1' },
      { value: 'OMU2' },
      { value: 'OMU3' },
      { value: 'OMU4' },
      { value: 'OMU5' },
      { value: 'OMU6' },
      { value: 'OMU7' },
      { value: 'OMU8' },
      { value: 'OMU9' },
      { value: 'OMU10' },
      { value: 'OMU11' },
      { value: 'OMU12' },
      { value: 'OMU13' },
      { value: 'OMU14' },
      { value: 'OMU15' },
      { value: 'OMU16' },
      { value: 'OMU17' }
    ],
    'Seraya Power Station': [
      { value: 'OSP1A' },
      { value: 'OSP1B' },
      { value: 'OSP2A' },
      { value: 'OSP2B' },
      { value: 'OSPS1' },
      { value: 'OSPS2' },
      { value: 'OSPS3' },
      { value: 'OSPS4' }
    ],
    'Vopak Sebarok': [
      { value: 'OSV2' },
      { value: 'OSV3' },
      { value: 'OSV4' },
      { value: 'OSV5' },
      { value: 'OSV6' },
      { value: 'OSV7' },
      { value: 'OSV8' },
      { value: 'OSV9' },
      { value: 'OSV10' }
    ],
    'Tank Store': [
      { value: 'OTS1' },
      { value: 'OTS2' },
      { value: 'OTS3' },
      { value: 'OTS4' },
      { value: 'OTS5' },
      { value: 'OTS5E' },
      { value: 'OTS5W' },
      { value: 'OTS6' },
      { value: 'OTS6E' },
      { value: 'OTS6W' },
      { value: 'OTS7' },
      { value: 'OTS8' },
      { value: 'OTS9' },
      { value: 'OTS10' }
    ],
    'Vopak Terminal': [
      { value: 'OVPJ1' },
      { value: 'OVPJ2' },
      { value: 'OVPJ3' }
    ],
    'CRUISE CENTRE': [
      { value: 'CC01' },
      { value: 'CC02' }
    ],
    'MARINA BAY CRUISE CENTRE': [
      { value: 'CM01' },
      { value: 'CM02' }
    ]
  },
  getTerminalBerth () {
    const self = this
    const terminal = JSON.parse(JSON.stringify(this.terminal))
    terminal.forEach(item => {
      item.children = self.berth[item.value]
    })
    return terminal
  }
}
